import {Injectable} from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { FunctionServiceService } from './BE_Service/function-service.service';


@Injectable({
  providedIn: 'root'
})

export class TokenService {

  private issuer = {
    login: 'http://127.0.0.1:8000/api/auth/login',
    register: 'http://127.0.0.1:8000/api/auth/register'
  };

  constructor(private AuthService: AuthService, private router: Router) { }

  // tslint:disable-next-line:typedef
  handleData(token , role , emaiVerified, user , permission) {
    localStorage.setItem('auth_token', token);

    if (emaiVerified !== 'null') {
      localStorage.setItem('emailVerified', emaiVerified);
      // var payload = JSON.parse(window.atob(localStorage.getItem('auth_token').split('.')[1]))
      // localStorage.setItem('avatar', user.avatar);
      const userData = JSON.stringify(user);
      localStorage.setItem('user', userData);
      localStorage.setItem('userId', user.id);
      localStorage.setItem('etatSidebar', 'large');
      localStorage.setItem('callendarType', 'users');
      localStorage.setItem('callendarView', 'timeGridWeek');
      localStorage.setItem('auth_Role', JSON.stringify(role));
      localStorage.setItem('auth_ permission', JSON.stringify(permission));
      localStorage.setItem('user_agreement', user.user_agreement);
    } else {
      localStorage.setItem('emailVerified', 'null');
    }
  }

  // tslint:disable-next-line:typedef
  getToken() {
    return localStorage.getItem('auth_token');
  }
  getRole() {
    return (localStorage.getItem('auth_Role'));
  }
  getDataUser() {
    return JSON.parse(localStorage.getItem('user'));
  }
  getUserID() {
    return JSON.parse(localStorage.getItem('userId'));
  }
  getEtatSidebar() {
    return (localStorage.getItem('etatSidebar'));
  }
  getCallendarType() {
    return (localStorage.getItem('callendarType'));
  }
  getCallendarView() {
    return (localStorage.getItem('callendarView'));
  }


  // Verify the token
  // tslint:disable-next-line:typedef
  isValidToken() {
    const token = this.getToken();
    if (token) {
      const payload = this.payload(token);
      if (payload) {
        return Object.values(this.issuer).indexOf(payload.iss) > -1 ? true : false;
      }
    } else {
      return false;
    }

  }
  // tslint:disable-next-line:typedef
  payload(token) {
    const jwtPayload = token.split('.')[1];
    return JSON.parse(atob(jwtPayload));
  }

  // User state based on valid token
  // tslint:disable-next-line:typedef
  /*
  isLoggedIn() {
    return this.isValidToken();
  }*/
  // tslint:disable-next-line:typedef
  isLoggedIn() {
    if (localStorage.getItem('auth_token')) {return true; }
    return false;
  }

  // tslint:disable-next-line:typedef
  isRole(roleToCheck: string): boolean {
    // tslint:disable-next-line:label-position no-unused-expression
    const storedRoles = localStorage.getItem('auth_Role');
    if (!storedRoles) { return false; }
    const roles: string[] = JSON.parse(storedRoles);
    return roles.includes(roleToCheck);
  }

  isPermission(permissionToCheck: string): boolean {
    // tslint:disable-next-line:label-position no-unused-expression
    const storedRoles = localStorage.getItem('auth_ permission');
    if (!storedRoles) { return false; }
    const roles: string[] = JSON.parse(storedRoles);
    return roles.includes(permissionToCheck.toLowerCase());
  }
  isRoleName(): string  {
    const storedRoles = localStorage.getItem('auth_Role');
    if (!storedRoles) { return null; }
    let roles = null;
    try {
      roles = JSON.parse(storedRoles);
    } catch (error) {
      console.error('Failed to parse roles from localStorage:', error);
      this.LogOut();
      return null;
    }


    const rolePriority = ['employer', 'admin', 'Super-Admin'];
    for (const role of rolePriority) {
      if (roles.includes(role)) {
        return role;
      }
    }

    return null;
  }
  // tslint:disable-next-line:typedef
  isemailVerified() {
    // tslint:disable-next-line:label-position no-unused-expression
    // @ts-ignore
    if ( localStorage.getItem('emailVerified') !== 'null' ) {
      return true ;
    } else {
      return  false ; }
  }
  isAcceptAgreement () {
    // tslint:disable-next-line:label-position no-unused-expression
    // @ts-ignore
    if ( localStorage.getItem('user_agreement') !== 'null' ) {
      return true ;
    } else {
      return  false ; }
  }
  // Remove token
  // tslint:disable-next-line:typedef
  removeToken() {
    localStorage.clear();
  }

  LogOut() {
    this.AuthService.logout({token: this.getToken()}).subscribe(
        (result) => {
          this.removeToken();
          this.router.navigateByUrl('login');
        },
        error => {
            console.log('erreur');
        }
    );
}
  // tslint:disable-next-line:typedef
  pageaccuiel() {
    if (this.isLoggedIn() && this.isemailVerified() === false) {
      return 'verify-email';
    } else if (this.isLoggedIn() && this.isemailVerified() === true &&  this.isRole('employer')) {
      return 'dashboard';
    } else if (this.isLoggedIn() && this.isRole( 'admin') ) {
      return 'admin/dashboard';
    } else if (this.isLoggedIn() && this.isRole( 'Super-Admin')) {
      return 'superadmin/dashboard';
    } else {
      return 'login';
    }
  }

  routeTo(route,homePage=false,blank=false){
    if(homePage){
      this.router.navigateByUrl(route);
    }else{
      const role = this.getRole()
      //let role = 'Super-Admin'
      console.log(role)
      let addToRout = ''
      if(role == 'Super-Admin'){
        addToRout = '/superadmin/'
      }else if(role == 'admin'){
        addToRout = '/admin/'
      }else if(role == 'employee'){
        addToRout = ''
      }
      this.router.navigateByUrl(addToRout+ route);
    }
  }

}
